@import '../styles/config';

.PasswordLogin {
  display: flex;
  flex-flow: column wrap;
  text-align: center;

  .goback {
    margin-top: 0;
  }
}

.PasswordLoginWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 20px 0;
  height: 100px;

  .primary-button {
    font-size: $size-title-s;
    padding: 20px;
    margin: 0;
    max-width: 140px;
  }
}

input[type='password'] {
  font-family: monospace;
  font-size: 8rem;
  letter-spacing: -0.05em;
  line-height: 1;
  margin: 0;
  padding: 20px;
  text-align: left;
  vertical-align: middle;
  max-width: 230px;
  height: 100px;

  &.FieldError {
    border: 5px solid lightcoral;
  }
}

.LoginError {
  color: $color-white;
  font-size: $size-title-s;
  text-align: center;
}

/*@media screen and (max-width: 1200px) {
  input[type='password'] {
    font-size: 15rem;
  }
}*/

@media screen and (max-width: $bp-s) {
  input[type='password'] {
    flex-basis: 160px;
    font-size: 5rem;
    max-width: 160px;
  }

  .PasswordLoginWrapper {
    .primary-button {
      flex-basis: 120px;
      max-width: 120px;
    }
  }
}

@media screen and (max-width: $bp-xs) {
  input[type='password'] {
    flex-basis: 140px;
    font-size: 4rem;
    max-width: 140px;
  }

  .PasswordLoginWrapper {
    .primary-button {
      flex-basis: 120px;
      max-width: 120px;
    }
  }
}
