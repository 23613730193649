ul,
ol {
  padding: 0;
  margin: 0;
}

ul,
ol {
  list-style: none;
}

button {
  background-color: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
}
