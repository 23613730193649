// Layout
$gap: 3rem;
$max-width: 180rem;

$text-max-width: 60rem;

$bp-l: 1040px;
$bp-m: 780px;
$bp-s: 640px;
$bp-xs: 340px;

// Colors
$color-black: #000000;
$color-white: #ffffff;

$color-gray: #b2b2b2;
$color-gray-light: #ececec;
$color-gray-dark: #2b2b2b;
$color-gray-darker: #1d1d1b;

$color-green: #3baa35;
$color-red: #bd332e; //Tidigare röd: #e6342a;

// Sizes
$size-base: 1.6rem;
$size-small: 1.2rem;
$size-pin: 10rem;
$size-title-l: 5rem;
$size-title-m: 3rem;
$size-title-s: 2rem;
$size-title-xs: 1rem;

// Fonts
$font-family: (helvetica, arial, sans-serif);
$line-height: 1.5;

:root {
  --emphasised-size: 2.5rem;
  --inner-max-width: 74rem;
}
@media screen and (max-width: $bp-s) {
  :root {
    --emphasised-size: 1.5rem;
    --inner-max-width: 90%;
  }
}
