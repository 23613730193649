@import "../styles/config";

#AnnouncementBanner {
  background: black;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;

  .Announcement-Title {
    font-size: 2rem;

    @media screen and (min-width: $bp-m) {
      display: flex;

      svg {
        margin-left: 1rem;
      }
    }
    @media screen and (max-width: $bp-m) {
      svg {
        width: 0.75em;
        height: 0.75em;
        margin-bottom: -0.25rem;
      }
    }
  }
}

.no-underline {
  text-decoration: none;
}
