@import "../styles/config";

.ProductList {
  /*display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: $gap;*/
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 80px (-($gap / 2));
  /*padding: 0 $gap;
  max-width: $max-width;
  margin: 0 auto;*/
  //gap: $gap;
}

.ProductItem-fields {
  display: flex;
  align-items: stretch;
  margin-top: auto;

  background-color: $color-gray-light;
  font-size: 1.5rem;

  & > * {
  }
  label,
  input {
    background-color: transparent;
  }
  label {
    padding-left: 0.5rem;
    font-weight: 400;
    line-height: 3.6rem;

    @media screen and (min-width: $bp-xs) and (max-width: $bp-s) {
      display: none;
    }
  }
  input {
    border: 0;
    width: 100%;

    text-align: right;
    font-size: var(--emphasised-size);
  }
}

.cartPage {
}

.ProductImage {
  padding: 3rem;

  .cartPage & {
    padding: 0;
  }
}

.ProductItem {
  flex: 1 1 20%;
  max-width: 20%;
  min-height: 100%;
  padding: $gap / 2;

  color: $color-gray-darker;
  text-align: center;

  .item-wrapper {
    height: 100%;
    //padding-top: 20px;
    //padding-bottom: 50px;
    display: flex;
    flex-flow: column wrap;
    align-items: stretch;
    padding: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h2 {
    font-size: $size-base;
    margin: 0;
    margin-bottom: 3rem;
    strong {
      display: block;
    }
  }

  input[type="number"] {
    appearance: textfield;
    background-color: $color-gray-light;
    color: $color-black;
    text-align: right;

    &::placeholder {
      color: $color-black;
    }
  }
}

.ProductItem-button {
  background: $color-gray;
  color: $color-white;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  border-left: 1px solid $color-white;
  padding: 1rem;
  text-transform: uppercase;
  transition: all 300ms ease;

  &.ProductItem-button.button-cart-goto {
    //width: 62px;
    max-width: 4rem;

    .icon-bag {
      font-size: var(--emphasised-size);
      position: absolute;
    }
  }
}

.button-cart-add {
  background-color: $color-gray-dark;

  &:hover,
  &:focus {
    transition: all 0.33s ease-in;
    background-color: darken($color-gray-dark, 10%);
  }
}
.button-cart-goto {
  background-color: $color-green;
  position: relative;
  width: 100%;

  &:hover,
  &:focus {
    background-color: darken($color-green, 10%);
  }
}

.button-cart-remove {
  background-color: $color-gray-dark;

  &:hover,
  &:focus {
    transition: all 0.33s ease-in;
    background-color: darken($color-gray-dark, 10%);
  }
}

.ProductList-footer {
  margin: $gap 0;
  text-align: center;
}

@media screen and (max-width: $bp-l) {
  .ProductItem {
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media screen and (max-width: $bp-m) {
  .ProductItem {
    flex-basis: 33.334%;
    max-width: 33.334%;
  }
}

@media screen and (max-width: $bp-s) {
  .ProductItem {
    flex-basis: 50%;
    max-width: 50%;

    h2 {
      font-size: 1.4rem;
    }
  }
}

@media screen and (max-width: $bp-xs) {
  .ProductItem {
    flex-basis: 100%;
    max-width: 100%;
  }
}
