@import "../styles/config";

#CurrentStepIndicator {
  margin: 6rem 0;
  counter-reset: number;

  text-align: center;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  @media screen and (max-width: $bp-m) {
    flex-flow: column nowrap;
  }
  & > div {
    counter-increment: number;
    margin: 0 1rem;
    padding: 0;
    text-align: center;

    color: #9e9e9e;
    &.current-step {
      color: white;
    }
    &.inactive-step {
      color: white;
    }
    &::before {
      content: counter(number) ". ";
    }
  }
}
