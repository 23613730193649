@import '_config.scss';
@import 'reset.scss'; // Simple custom css reset

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  background-color: $color-gray;
  color: $color-white;
}

html {
  font-size: 62.5%; // 1rem = 10px
}

body {
  font-family: $font-family;
  font-size: $size-base;
  font-weight: 300;
  line-height: $line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: $size-title-m;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;

  @media screen and (max-width: $bp-m) {
    font-size: $size-title-s;
  }
}

strong {
  font-weight: 700;
}

.preamble {
  font-size: $size-title-s;
}

button {
  color: $color-gray-darker;
  font-size: $size-small;
}

a {
  color: $color-white;
  text-decoration: underline;
}

.PageTitle {
  text-align: center;
}

.UserHi {
  font-size: $size-title-l;
  text-align: center;

  @media screen and (max-width: $bp-l) {
    font-size: $size-title-m;
  }

  @media screen and (max-width: $bp-s) {
    font-size: $size-title-s;
  }
}

.App {
  @media screen and (max-width: $bp-s) {
  }
}
// Layout
.wrapper {
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 $gap;
}

.content {
  margin-top: 6rem;
}

@mixin button-transition {
  transition: all 0.2s linear;
}

// Buttons
.primary-button {
  appearance: none;
  background-color: $color-green;
  border: none;
  border-radius: 0;
  color: $color-white;
  cursor: pointer;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  font-size: $size-base;
  max-width: 320px;

  padding: 20px $gap;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &[disabled] {
    opacity: 0.7;
  }

  .icon {
    font-size: 2rem;
    margin-left: 0.334em;
  }

  &:hover {
    filter: brightness(1.05);
  }

  @include button-transition;
}
button.block {
  display: block;
}
.secondary-button {
  @extend .primary-button;
  background-color: #e94e1b; //#d75e3a;
  display: inline-block;
}

.outlined-button {
  @extend .primary-button;
  background-color: transparent;
  border: 1px solid $color-white;
  display: inline-block;

  &:focus,
  &:hover {
    color: $color-gray-dark;
    background-color: $color-white;

    transition: all 0.33s ease-in;
  }
}

// Other shared classes
.item-wrapper {
  background-color: $color-white;
  padding: $gap ($gap / 2);
  position: relative;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  &.bold {
    stroke-width: 1;
  }

  .bold-icons & {
    stroke-width: 1;
  }
}

.hidden {
  display: none;
}

.center-block {
  display: block;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.flex-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 4rem;
  gap: 2rem;
  & > * {
    flex: 1;
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }

  button {
    @media screen and (max-width: 320px) {
      &:nth-of-type(1n) {
        margin-top: 0;
        order: 2;
      }
      &:nth-of-type(2n) {
        order: 1;
      }
    }
  }
}
@media screen and (max-width: $bp-s) {
  .hide-in-mobile {
    display: none;
  }
}

@import './filters.scss';
@import './fields.scss';
@import './typography.scss';
