@import '../styles/config';

.UsersList {
  column-count: 3;
  column-gap: $gap;
  max-width: 1200px;
  margin: 0 auto;
}

.UserItem {
  a {
    color: $color-white;
    font-size: $size-title-m;
    text-decoration: none;
  }
}

@media screen and (max-width: $bp-m) {
  .UserItem {
    a {
      font-size: $size-title-s;
    }
  }
}

@media screen and (max-width: $bp-s) {
  .UsersList {
    column-count: 2;
  }

  .UserItem {
    a {
      font-size: $size-base;
    }
  }
}

@media screen and (max-width: $bp-xs) {
  .UsersList {
    column-count: 1;
    text-align: center;
  }
}
