.text-flow {
  .preamble {
    font-weight: 400;

    text-align: center !important;
  }

  text-align: center;
  margin: {
    left: auto;
    right: auto;
  }

  & > * + * {
    margin: {
      up: 2rem;
      down: 2rem;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: {
      left: auto;
      right: auto;
    }
    text-align: center;
  }
  p {
    /*
    .page &{
      text-align: start;
    }
    */
    max-width: var(--inner-max-width);

    .ReportSubmitted & {
      max-width: 55rem;
    }

    margin: {
      left: auto;
      right: auto;
    }
  }
  label {
    text-align: start;
  }
}
