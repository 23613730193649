.select-field-wrapper {
  position: relative;
  max-width: 250px;
  margin: 0 auto;

  &:after {
    content: '\25b6';
    line-height: 1;
    pointer-events: none;
    margin-top: -0.5em;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: rotate(90deg);
  }
}

select {
  appearance: none;
  border: 1px solid $color-gray;
  border-radius: 10px;
  font-size: $size-small;
  padding: 10px;
  text-align: left;
  width: 100%;
}
