@import '../styles/config';

.CartList-form {
  text-align: left;

  &.simple {
    margin-bottom: 10rem;
  }

  label {
    display: inline-block;
    margin-bottom: 0.4rem;
  }
}

.CartList {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .only-simple {
    display: none;

    .simple & {
      display: block;
    }
  }
  margin-bottom: 8rem;
}

.CartList-footer {
  margin: $gap 0;
  text-align: center;
}

.CartGroupDivider {
  border: 0;
  background-color: $color-white;
  color: $color-gray-darker;
  position: relative;
  margin: {
    top: $gap;
    bottom: $gap;
  }

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  height: 1px;
  width: 100%;

  .simple & {
    margin: {
      top: 1rem;
      bottom: 1rem - 0.5rem; /* Account for next CartItem margin-top of .5rem */
    }
  }
}

.CartItem {
  background-color: $color-white;
  color: $color-gray-darker;
  padding: 2rem 4rem;
  position: relative;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;

  padding: 0;

  &.CartItem-has-errors {
    background-color: rgb(255, 235, 235);
    outline: 3px solid red;
  }

  + .CartItem {
    margin-top: $gap;
  }

  img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

  .ProductImage {
    flex: 1 1 200px;
    max-width: 200px;
    padding-right: 40px;

    .cartPage & {
      img {
        margin: 2rem;

        @media screen and (max-width: $bp-m) {
          margin: 2rem;
        }
        @media screen and (max-width: $bp-s) {
          margin: 2rem 0;
        }
      }
    }

    .simple & {
      flex: initial;
      max-width: initial;
      padding-right: initial;
      margin-right: 1rem;
      /*
      flex: 1 1 auto;
      max-width: 5rem;
      padding: 0.5rem;
      display: flex;  
      margin: .5rem;
      */
      img {
        height: 5rem;
        margin: 1rem;
      }
    }
  }

  .simple & {
    padding: 0;
    margin-top: 0.5rem;

    flex-wrap: nowrap;
  }
}

.CartItem-title {
  font-size: $size-title-s;
  text-align: start;

  @media screen and (max-width: $bp-m) {
    text-align: center;
    margin-top: $gap/1.5;
    margin-bottom: $gap/1.5;
    padding: 1rem;
  }

  strong {
    display: inline-block;
    margin-right: 0.3em;
  }

  margin: 0.5rem 0;

  .simple & {
    font-size: 90%;
  }
}

.CartItem-fields {
  flex-basis: calc(100% - 240px);
  max-width: calc(100% - 240px);
  text-align: left;
  margin-left: auto;

  label {
    display: block;

    .simple & {
      display: none;
    }
  }

  .simple & {
    display: flex;
    flex-basis: calc(100%);
    max-width: calc(100%);
    align-items: center;

    p {
      margin-left: auto;
    }
  }
}

.simple-only {
  padding-left: 1rem;
  font-weight: 400;
  line-height: 3rem;

  @media screen and (max-width: 320px) {
    margin-right: auto;
  }
}
.CartItem-field {
  .simple & {
    margin-left: auto;
    font-weight: 600;
    margin-right: 2rem;

    .simple-only {
      display: inline-block;
    }
  }
}
.CartItem-actions {
  .simple & {
    display: none;
  }
}

.CartItem-field,
.CartItem-actions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  @media screen and (max-width: $bp-m) {
    display: initial;
    flex-flow: initial;
    align-items: initial;
    justify-content: initial;
    padding: initial;
  }

  .simple & {
    border: 0;
    display: block;
  }

  input[type='number'] {
    appearance: textfield;
    background-color: $color-gray-light;
    border: none;
    box-shadow: none;
    color: $color-gray-darker;
    flex-basis: 96px;
    font-size: var(--emphasised-size);
    height: 50px;
    max-width: 96px;
    padding: 0 3px;
    text-align: right;
    margin-left: auto;
    min-width: 50%;

    &::placeholder {
      color: $color-black;
    }

    @media screen and (max-width: 320px) {
      min-width: initial !important;
      max-width: initial !important;
      flex-basis: initial !important;
      width: 100%;
    }
  }

  .select-field-wrapper {
    flex-basis: 250px;
    max-width: 250px;
  }
}

.CartItem-actions {
  justify-content: flex-end;
}

.CartItem-amount-inputs {
  background-color: $color-gray-light;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;

  > * + * {
    margin-left: 1px;
  }

  .simple & {
    font-weight: 600;

    @media screen and (min-width: $bp-m) {
      display: block;
    }

    background-color: transparent;
    font-size: 90%;
  }

  .cart-count {
    width: 1.5rem;
    display: inline-block;

    font-weight: bold;
    padding-left: 0.5rem;
  }
}

.CartItem-amount-button {
  background-color: $color-gray-darker;
  color: $color-white;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: 600;
  height: 50px;
  min-width: 50px;

  .simple & {
    display: none;
  }

  &:hover,
  &:focus {
    transition: all 0.5s ease-in-out;
    background-color: #000;
  }
}

.CartItem-remove-button {
  background: $color-white;
  color: $color-gray-darker;
  font-size: 2rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  border: 1px solid $color-gray-darker;
  margin: 1rem;

  padding: 0;
  height: 2rem;
  width: 2rem;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  text-transform: uppercase;
  transition: all 300ms ease;

  .simple & {
    display: none;
  }

  &:focus,
  &:hover {
    //background-color: darken($color-red, 10%);
    color: $color-gray-light;
    border-color: $color-gray-light;
  }

  svg {
    width: 0.8rem;
    height: 0.8rem;

    stroke-width: 1;
  }
}

@media screen and (max-width: $bp-s) {
  .CartItem {
    .ProductImage {
      padding: 0;
      margin: 0 auto;
    }
  }
  .CartItem-fields {
    margin-top: 0;
    flex-basis: 100%;
    max-width: 100%;
  }

  .CartItem-field,
  .CartItem-actions {
    input[type='number'] {
      flex-basis: 65px;
      font-size: 3rem;
      max-width: 65px;

      @media screen and (max-width: $bp-m) {
        width: 100%;
        max-width: initial;
        flex-basis: initial;
      }
    }
  }

  .CartItem-amount-inputs {
    justify-content: flex-end;
    margin-top: 5px;
    //width: 100%;

    .icon {
      stroke-width: 1;
    }
  }
}

@media screen and (max-width: $bp-xs) {
  .CartItem-field,
  .CartItem-actions {
    input[type='number'] {
      flex-basis: 45px;
      font-size: 3rem;
      max-width: 45px;
    }
  }

  .CartItem-amount-inputs {
    > * + * {
      margin-left: 3px;
    }
  }
}
