.FilterList {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: $gap * 2;
}

.FilterItem {
  button:nth-of-type(2n) {
    background: red;
    color: orange;
  }
  button {
    background-color: $color-gray;
    border: 1px solid $color-white;
    color: $color-white;
    padding: $gap / 2;
    min-width: 150px;
    transition: 150ms ease all;

    @media screen and (max-width: $bp-m) {
      margin-left: -1px;
      margin-top: -1px;

      &:nth-of-type(4n) {
        background: red;
        color: orange;
      }
    }

    @media screen and (max-width: $bp-xs) {
      margin-left: initial;
      margin-top: -1px !important;

      &:nth-of-type(4n) {
        background: red;
        color: orange;
      }
    }
  }

  button:hover,
  &.active button {
    background-color: $color-white;
    color: $color-gray;
    border-color: $color-white;
  }

  + .FilterItem {
    margin-left: -1px;
  }
}
