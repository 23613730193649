@import '../styles/config';

.submitReturn {
  @media screen and (min-width: $bp-s) {
    max-width: var(--inner-max-width);
  }
}

#returns-headline {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6rem;
  cursor: pointer;
}
#showReturns {
  transform-box: fill-box;
  transition: all 0.2s linear;
  width: 1rem;
  height: 1rem;
  margin: 0 1rem;
}

form {
  label {
    font-weight: 400;
  }
  label.radio {
  }
  input[type='text'],
  input[type='email'] {
    display: block;
    width: 100%;
    height: 4rem;
    border: 0;
    padding: {
      left: 1rem;
      right: 1rem;
    }
    margin: {
      top: 0;
      bottom: 2rem;
    }
  }
  .flex-vertical-center {
    display: flex;
    align-items: center;
    margin: 0rem 0rem 2rem 0;

    input[type='radio'] {
      margin: 0;
    }
    label {
      margin: 0;
    }

    @media screen and (max-width: 320px) {
      display: grid;
      grid-gap: 1rem;
    }
  }

  input[type='checkbox'].styled-checkbox {
    display: none;
  }
}
input {
  font-size: 1.6rem;
}
input[type='radio'].styled-radio {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}

input[type='radio'].styled-radio {
  z-index: 999;
}

label.styled-radio {
  background-image: url('../images/button.svg');
}

input[type='radio']:checked + label.styled-radio {
  background-image: url('../images/button_filled.svg');
}

input[type='radio']:checked + label.styled-radio {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}
.styled-radio {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;

  padding: 0 3rem;
  -webkit-transition: filter 100ms ease-in;
  -moz-transition: filter 100ms ease-in;
  transition: filter 100ms ease-in;
  -webkit-filter: brightness(1.8) grayscale(1) opacity(0.7);
  -moz-filter: brightness(1.8) grayscale(1) opacity(0.7);
  filter: brightness(1.8) grayscale(1) opacity(0.7);
}
.styled-radio:hover {
  -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  filter: brightness(1.2) grayscale(0.5) opacity(0.9);
}

/*** ***/

input[type='checkbox'].styled-checkbox {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='checkbox'].styled-checkbox {
  z-index: 999;
}

label.styled-checkbox {
  background-image: url('../images/button.svg');
}

input[type='checkbox']:checked + label.styled-checkbox {
  background-image: url('../images/button_filled.svg');
}

input[type='checkbox']:checked + label.styled-checkbox {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}
.styled-checkbox {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;

  padding: 0 3rem;
  -webkit-transition: filter 100ms ease-in;
  -moz-transition: filter 100ms ease-in;
  transition: filter 100ms ease-in;
  -webkit-filter: brightness(1.8) grayscale(1) opacity(0.7);
  -moz-filter: brightness(1.8) grayscale(1) opacity(0.7);
  filter: brightness(1.8) grayscale(1) opacity(0.7);
}
.styled-checkbox:hover {
  -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  filter: brightness(1.2) grayscale(0.5) opacity(0.9);
}
