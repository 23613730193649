@import "../styles/config";

.AppHeader {
  background-color: rgba($color-gray, 1);
  position: sticky;
  top: 0;
  padding-top: 10px;
  margin-bottom: $gap * 2;
  z-index: 500;

  .wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    height: 10rem;

    // A fancy border-bottom...
    // Why? Due to border-bottom within .wrapper would not be within grid (do not want to add an new parent element)
    &:after {
      background-color: $color-white;
      display: block;
      flex-basis: 100%;
      content: "";
      max-width: 100%;
      margin-top: 10px;
      height: 1px;
    }
  }

  &.announcement {
    margin-bottom: 0;

    .wrapper:after {
      background-color: transparent;
    }
  }
}

.AppLogo {
  color: $color-white;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  font-size: $size-title-s;
  font-weight: 300;
  text-decoration: none;
}

.AppLogo-symbol {
  height: 3rem;
  width: auto;
  margin-right: 3rem;
  @media screen and (max-width: $bp-s) {
    margin-right: 0rem;
  }
}

.AppMenu {
  display: flex;
  flex-flow: row wrap;

  li + li {
    margin-left: 20px;
  }
}

.NavItem {
  position: relative;

  a {
    color: $color-white;
    font-size: $size-title-s;
    text-decoration: none;

    display: flex;
    flex-flow: row wrap;
    align-items: center;

    &.active {
      opacity: 0.7;
    }

    .icon {
      font-size: 2rem;
      margin-left: 0.334em;
    }
  }
}
.NavItem-cartcount {
  background: $color-white;
  border-radius: 100%;
  color: $color-gray-darker;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  position: absolute;
  top: -9px;
  right: -9px;
  height: 1.5em;
  width: 1.5em;

  @media screen and (max-width: $bp-s) {
    font-size: 1.01rem; // Bug distorts font @ 1 rem in some mobile devices
  }
}
.item-added {
  .icon {
    animation: 1s 1 iconPop;
  }
}

.cart-is-not-empty {
  a {
    background: $color-green;
    color: $color-white;
    padding: 0 5px;
  }
  a,
  a .icon {
    //color: $color-green;
  }
}

@keyframes iconPop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2.5);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: $bp-m) {
  .AppLogo {
    position: relative;
    overflow: hidden;
    text-indent: -9000px;

    &,
    .AppLogo-symbol {
      //height: 40px;
      //width: 40px;
    }
  }

  .NavItem {
    a {
      font-size: $size-base;
    }
  }
}

.AppNavigation {
  //flex-basis: 100%;
  //max-width: 100%;

  @media screen and (max-width: 320px) {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
  }
}

@media screen and (max-width: $bp-s) {
  .NavItem {
    a {
      font-size: 1.4rem;
    }
  }

  .AppMenu {
    justify-content: flex-end;
    padding: 12px 0 0 0;
    margin-top: 0;
    li + li {
      margin-left: 15px;
    }
  }

  /*
  .AppLogo {
    display: none;
    .AppLogo-symbol {
      height: 40px;
      width: 40px;
    }
  }
  */
}
