@import "../styles/config";

menu#LanguageSelector {
  display: flex;
  padding: 0;
  margin: 0;
  margin-right: auto;

  button {
    color: #fff;
    padding: 0.25rem 1rem;
    &:not(:first-child) {
      border-left: 1px solid #ffffff50;
    }
  }

  @media screen and (max-width: $bp-s) {
    margin-right: initial;
  }
}
