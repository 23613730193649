@import '../../styles/config';

@keyframes ball-scale-multiple {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.loader {
  display: block;
  margin: $gap auto;
  height: 60px;
  width: 60px;
}

.ball-scale-multiple {
  position: relative;
  transform: translateY(-30px);
}

.ball-scale-multiple > div:nth-child(2) {
  animation-delay: -0.5s;
}

.ball-scale-multiple > div:nth-child(3) {
  animation-delay: -0.25s;
}

.ball-scale-multiple > div {
  background-color: $color-white;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  position: absolute;
  left: -30px;
  top: 0px;
  opacity: 0;
  margin: 0;
  width: 60px;
  height: 60px;
  animation: ball-scale-multiple 1.25s 0s linear infinite;
}
